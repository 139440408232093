
 .mainBody{
    overflow: hidden;
 }

 .navbarBody{
    position: fixed;
    top: 0;
    z-index: 20;
    display: flex;
    font-family: Roboto;
    justify-content: space-between; 
    background-color: #fff; 
    height: 95px;
    width: 100vw;
}
.btn-primary {
    font-weight: 500 ! important;  
    font-family: Roboto ! important;
    color: #000 ! important;
    background-color: transparent ! important;
    border-color: transparent ! important; 
}
.btn{
    padding:0rem ! important;
    font-size: 95% ! important;
}
button:focus {
    outline: none ! important;
}
.btn-primary.focus, .btn-primary:focus {
    box-shadow: none ! important;
}
/* .DropdownButton{
    height: auto;  
    width: 120px;
    margin-right: 1rem;
} */


.links a{
    text-decoration: none ! important;
    margin-right: 1rem;
    font-size: 95%;
    color: #000 !important;
    font-weight: 500; 
    cursor: pointer; 
    /* background-color: #fff;   */
    /* height: auto;  
    width: 120px; */
}
.links  a:hover{
    /* background-color: #05454E;
   box-shadow: 5px 5px 5px 15px #05454E; */
   font-weight: bold;
   /* font-size: 12px; */
}
/* Link:hover{
    color: red! important;
} */
.links{
    margin-top: .6rem;
    padding: 2rem;
    display: flex;
}
.btn:hover{
    font-weight: bold !important;

}
.logo img {
    margin-left: 80px;
     height: auto;
     width: 200px;
     padding: 0rem;
     padding-top: 15px;
 }

.first-section{
    margin-top:100px;
    margin-bottom: 2rem;
    height: auto;
    /* width:100vw; */
}
.a{
    display: flex;
    justify-content: space-between;
}
.first{
    height: 100%;
    width: 60vw;
}
.feature{
    display: flex;
    font-family: Roboto;
    padding-top:2rem;    
    justify-content: flex-end;
    height: 200px;    
    padding: 2rem; 
    margin-right: 5%;   
}
.fea{
    width: 230px;
    margin-left: 1rem;
}
.fea h2{
    font-size: 98%;
    margin-bottom: 0rem;
}
.feature p{
    font-size: 90%;
   font-weight: 300;
}

.feature1{
    display: grid;
    justify-content: center;
}
.feature1 img{
    height: 20px;
    width:20px;
}
.feature2{
    display: grid;
    justify-content: center;
}
.feature2 img{
    height: 20px;
    width:20px;
}
.feature3{
    display: grid;
    justify-content: center;
}
.feature3 img{
        height: 20px;
        width:20px;
}

.txt-1{
    font-family: Roboto;
    padding-left: 4rem;
    height:20rem;
    width: 30vw;
    align-self: center;
    font-size:110%;
}
.txt-1 h1{
    font-weight: 900;
    font-size:220%;
    padding-bottom: 2rem; 
}
.second-section{
    display: flex;
    align-Items: center;
    height:100%;
    width: 100vw;
}
.second-section img{
    height:auto;
    width: 50vw;
}
.txt-2{
    font-family: Roboto;
    font-weight: 300;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 0rem;
}

.txt-2 h1{
    font-weight: 700;
    font-size:250%; 
    margin-bottom: 8%;
    color: #000;    
}
.txt-2 p{
    font-size: 100%;
    font-weight: 300;
    text-align: justify;
}
.company-profile{
    display:grid;
    justify-content: center;
    margin-top: 2rem;  
}
 .company-img{
     display: none;  
}
/* .company-img img{
    height: 100%;
    width: 100%;
} */ 
.profileBody{
    display: grid;
    justify-content: center;
    font-family: Roboto;
    color: #fff;
    padding: 5% 0 6% 0;
    margin-top: 5%;
    background-image: url('../Images/New folder/adsa.jpg');
    background-size: 100% 100%;

}
.profile{
    display: flex;
    justify-content: space-between;   
}
.profile p{
    padding-right:5%;
}
.leftSide{
    height: auto;
    width: 45%;
    padding: 5%  5% 2% 5%;
    background-color: #05454E;
    opacity: 0.66;
    border-radius: 0px 0px 20px 0px;
}
.rightSide{
    height: auto;
    width: 45%;
    padding: 5%  5% 2% 5%;
    background-color: #05454E;
    opacity: 0.66;
    border-radius: 0px 0px 0px 20px;
}
.profile p{
    font-size: 100%;
    font-weight: 300;
    text-align: justify;
}
.profile ul{
    list-style: square;
}
.profileBody h1{
    font-size: 250%;
    font-weight: 700;
    padding-bottom: 5%;
    text-align: center;
}
.profile h2{
    font-size: 150%;
  
}
.productBody{
    display: flex;
    align-Items: center;
    height: 100%;
    width: 100vw;
    position: relative;
   
}
.third-img img{
    height: auto;
    width: 50vw;
}
.third-img1{
    display: none;
}
.txt-3{
    margin-top: 3rem;
    font-family: Roboto;
    font-weight: 300;
    padding: 3rem;
}
.product h1{
    font-size: 250%;
    font-weight: 700;
    padding: 5%;
    text-align: center;
}
.txt-3 h2{
    font-size: 200%;
    font-weight: 500;
    padding-bottom: 1rem;
}
.txt-3 p{
    font-size: 100%;    
    font-weight: 300;
    text-align: justify;
}
 .game-dev{
    display:grid;
    height: auto;
    width: 100%;
    margin-top: 3rem;
    opacity: .9;
}
.game-dev-img{
   display: none;
}
.game-dev-txt{
    /* position: absolute; */
    color: #fff;
    text-align: center;
    font-size: 100%;
    font-weight: 300;
    padding: 10%;
    background-image: url('../Images/New folder/image_Product_Service_GameDev.png');
    background-size: 100% 100%;
}
.game-dev-txt h1{
    font-size: 200%;
    font-weight: 500;
    padding-bottom: 1rem;
}

.graphicDesign{
    display: flex;
    align-Items: center;
    height:100%;
    width: 100vw;
    margin-top: 3rem;
}
.graphic-img img{
    height:auto;
    width: 50vw;
}
.graphic-txt{
    font-family: Roboto;
    text-align: left;
    font-size: 100%;
    font-weight: 300;
    padding: 3rem;
}
.graphic-txt h1 {
    font-size: 200%;
    font-weight: 500;
    padding-bottom: 2rem;
}
.graphic-txt {
    font-size: 100%;
}
.graphic-txt p{
    text-align: justify;
}
.managedIT{
    display: flex;
    align-Items: center;
    height:100%;
    width: 100vw;
    margin-top: 3rem;

}
.managedIt-img img{
    height:auto;
    width: 50vw;
    display: block;
}
.managedIt-img1{
    display: none;
    }
.managedIt-txt{
    font-family: Roboto;  
    font-size: 100%;
    font-weight: 300;
    padding: 2rem;
}
.managedIt-txt h1{
    font-size: 200%;
    font-weight: 500;
    padding-bottom: 2rem;
}
.managedIt-txt p{
    text-align: justify;
}
.managedIt-txt ul{
    font-size: 100%;
    list-style: square;
    font-weight: 500;
}
.partner{
    display: grid;
    justify-content: center;
    text-align:center;
    height: 300px;
    width: auto;
    background-color: #F4F4F4;
    padding-top: 3rem;
    margin-top: 3rem;
}
.partner h1{
    font-size: 250%;
    font-weight: 700;
}
.company img{
  margin-right: 5rem;
    
}
.company1, .company3{
    height: auto;
    width:20%;
}
.company4{
    height: auto;
    width:15%;  
}
.client-profile{
    font-family: Roboto;  
    display: grid;
    height: 100%;
    width: 100vw;
    justify-content: center;
    padding-top: 2rem;
    text-align: center;
    margin-bottom: 3rem;
}
.client-link a{
    text-decoration: none ! important;
    color: #000 !important;
    margin-right: 2rem;
    padding-top: 3rem;
    font-weight: 500;
    text-align: center;
}
.client-profile h1{
    font-size: 250%;
    text-align: center;
    margin-bottom: 3rem;
    font-weight: 700;
    margin-top: 4rem;
}
.client-details{
    display: flex; 
    justify-content:center;
}
.client-details p{
    padding-top: 20px;
    height: auto;
    width: 200px;
    font-size: 100%;
    font-weight: 300;
    text-align: left;
   
}
.client-info img{
    height: 80px;   
}
.client-info{  
    height: auto;
    width: 200px;
    padding: 5%;
    margin-right: 5%;
}
.clientImage{
    margin-left: 1rem;
}
.clientImage img{
height: auto;
width: 12%;
margin-right: 2rem;
}

#clientImage5{
    height: auto;
    width: 5%;
}
.siemens{
    height: auto;
    width: 16vw;
}
.projects{
    font-family: Roboto;
    display: flex;
    height: auto;
    width: 100vw;
    /* justify-content: center; */
    background-color: #F4F4F4;
    padding: 3rem;
}
.project-details{
    margin-left: 3rem;
    height: auto;
    width: 60vw;
    padding: 1rem;
}
.project-details label{
    font-size: 150%;
}
.project-details p{
    font-size: 100%;
    font-weight: 300;
}
.project-txt h1{
    height: auto;
    width: 10vw;
    font-size: 430%;
    font-weight: 1000;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
}
.footerBody{
    padding: 5%;
    height: auto;
    /* width: 100%; */
}
.footerBody h1{
    display: none ;
   
}
.contactDetails h1{
    display: block;
    font-size: 250%;
    font-weight: 700;

}
.footer{
    font-family: Roboto;
    display: flex;
    justify-content: space-around;
    
}
.form{
    font-size: 110%;
    text-align: left;
    /* height: auto;
    width: 40vw; */
}
.nameBody{
    display: flex;
    justify-content: space-between;
}
.nameBody input{
    width: 45%;
}
.contactDetails{
    padding-top: 6%;
}

.contactDetails label{
   font-size: 250%;
   font-weight: 700;
}
.contactDetails1{
    display: none;
    padding-top: 6%;
}
.footer-link{
    display: flex; 
    justify-content: space-between;
    margin-top: 5%;
   
}
.footer-link  a{
    height: auto !important;
    /* width: 100% !important; */    
    margin-top: 0%;
}
.footer-link img{
    height: 70%;
    /* width: auto; */
    margin-right: 15%;
}

.form-control{
    border: none !important;
    height: auto! important;
    /* width: 100% ! important; */
}
.contactInfo{
    /* border: .5px solid #000;
    border-radius: 5px; */
    padding: 2%;    
    height: 100%;
    width: 40%;
    margin-top: 3%;
}
form{
    display:grid;
    justify-content:center;
    padding: 2%;
    
}
/* .contactInfo:hover{
    border-radius: 5%;
    box-shadow: 5px 5px 5px 10px #F7F7F7;
} */
form input{
    background-color:#F7F7F7;
    border: 0.205px solid #969696;
    box-sizing: border-box;
    margin-bottom: .5rem;
    height: 2rem;
    width: 100%;

}
form textarea {
    background-color:#F7F7F7;
    border: 0.205px solid #969696;
    box-sizing: border-box;
    margin-bottom: .5rem;
    height: 100%;
    width: 100%;
}
 button{
    height: auto;
    width: 25%;
    background-color:#000;
    border-radius: 6px ! important;
    border: none;
    color:#fff;
    font-weight: 500;
    text-align: center;
    
}
.form button{
    position: relative;
    right: -70%;
}
::-webkit-input-placeholder {
    /* text-align: center; */
   padding-left: 5%;
   font-size: .8rem;
   font-style: italic;
  }
  
  :-moz-placeholder {
    /* text-align: center; */
    padding-left: 5%;
  }
button:focus{
    outline: none;
}
.hamburger{
    display: none;
}

.upbtn{
    height: auto;
    width: 3.5%;
    opacity: .55; 
    position: fixed;
    float: right;
    z-index: 100;
    right: 2rem;
    bottom: 70px;
    border-radius: 50% 50%;
}
.copyright{
    font-family: Roboto;
    text-align: center;
    padding-bottom: 3%;
    margin-top: 2%;
}

/* mobile view*/
@media only screen and (max-width: 768px) {
    .navbarBody {
        background-color: #fff;
        height: 15%;
        width: 100vw;
    }
   
    .logo img {
        margin-left: 50px;
         height: auto;
         width: 55%;
         padding: 0rem;
         padding-top: 6%;
         position: absolute;
     }
    .hamburger{
        display: block;
        /* position: relative;  
        align-items: right; */
    }
    .menu1{
        position: relative; 
        top: .5rem;
        right: -60%;
        z-index: 100;
    }
    .menu2{
        display: none;       

    }
    .h {
        display: grid;
        justify-content: right;
        text-align: right;
        height: 400px;
        width:40vw;
        position: relative;
        left: 57%;
        font-size: 80%;
        padding-top: 1rem;
        margin-top: 1rem; 
        padding-right: 1.2rem; 
        background-color: #f2f2f2 ;
        z-index: 99;
    }
    
    .backdrop{
        display: none;
        /* background-color: #2d80973b; */
        backdrop-filter: blur(2px);
        height: 100vh;
        width: 100vw;
        cursor: pointer;
        z-index: 98;
        position: absolute;
        
    }
    #Ham{
       margin-top: 2rem;
       margin-bottom: 1rem;
        height: 10%;
        width: 10%;
        position: relative;
        right: -23%;
    }
    .menu2 a{
        text-decoration: none ! important;
        margin-right: 0rem;
        font-size: 100%;
        color: #000;
        font-weight: 500; 
        cursor: pointer; 
        background-color: none;    
    }
    .btn-primary {
        font-weight: 500 ! important;  
        font-family: Roboto ! important;
        color: #000 ! important;
        background-color: #f2f2f2 ! important;
        border-color: #f2f2f2 ! important; 
    }
    .btn{
        padding:0rem ! important;
        font-size: 98% ! important;
    }
    #nav-dropdown a{
        background-color: #f2f2f2 ! important;
        border-color: #f2f2f2 ! important; 
    }
    .dropdown, .dropleft, .dropright, .dropup {
       
        margin-left: -380px;
    }

.links{
    display: none;
    padding: 0;
    font-size: 50%;
}

 .mainBody{
     display: grid;
     justify-content: left;
     
 }
 
.first-section{
    margin-top:150px;
    margin-bottom: 2rem;
}
.a{
    display: grid;
}
.first{
    height: 100%;
    width: 100vw;
}
.feature{
    display:grid;
    font-family: Roboto;
    padding-top:2rem;    
    justify-content: left;
    height: auto;
    padding: 1rem;  
    margin-right: 0rem;
}
.fea{
    height: auto;
    width: 60vw;
}
.feature p{
    font-size: 90%;
   font-weight: 300;
}

.feature1{
    display: grid;
    justify-content: center;
    
}
.feature1 img{
    height: 30px;
    width:30px;
}
.feature2{
    display: grid;
    justify-content: center;
}
.feature2 img{
    height: 30px;
    width:30px;
}
.feature3{
    display: grid;
    justify-content: center;
}
.feature3 img{
        height: 30px;
        width:30px;
}

.txt-1{
    font-family: Roboto;
    padding-left: 7%;
    height:20rem;
    width: 80vw;
    align-self: center;
    font-size:100%;
}
.txt-1 h1{
    font-weight: 900;
    font-size:160%;
    padding-bottom: 0rem; 
}
.second-section{
    display: grid;
}
.second-section img{
    height:auto;
    width: 100vw;
}
.txt-2 {   
    padding-left: 1rem;
    margin-top: 5%;
    padding: 0 7% 0 7%;   
}
.txt-2 p{
    text-align: justify;
    padding: 2%;
}
.txt-2 h1{
    margin-top: 5%;
    font-size: 200%;
    margin-bottom: 1rem;
    font-weight: 700;
    padding-left: 2%;
}
.company-profile{
    display:grid;
    height: 100%;
    width: 100vw;
}
.company-img{
    display: block;
}
.company-img img{
    height: auto;
    width: 100%;
}
.profileBody{
    display: grid;
    justify-content: center;
    position: relative;
    margin: 0rem;
    padding: 3%;
    background-image: none;
}
.profile{ 
    display: grid;
    justify-content: center;   
    font-family: Roboto;
    color: #000;
    height: auto;
    width: 100vw;
    padding: 0rem;
    margin-left: 0rem;
    margin-top: 0rem;
    z-index: 10;
    
}
.leftSide {
    height: auto;
    width: 100vw;
    padding: 5%;
    background-color: #fff;
    opacity: 1;
    border-radius: 0px 0px 0px 0px;
}
.leftSide p{
    text-align: justify;
    padding-right: 7%;
}
.rightSide {
    height: auto;
    width: 100vw;
    padding: 5%;
    background-color: #fff;
    opacity: 1;
    border-radius: 0px 0px 0px 0px;
}
.rightSide p{
    text-align: justify;
    padding-right: 7%;
}

.profileBody h1 {
    font-size: 200%;
    padding-bottom: 0rem;
    text-align: left;
    color: #000;
    margin-top: 6%;
    margin-left: 5%;
    font-weight: 700;
}

.product{
    display: grid;
    height: 100%;
    width: 100vw;
    position: relative;
    margin-top: 0rem;
}
.third-img img{
    height: auto;
    width: 100vw;
    display: none;
    
}
.third-img1{
    height: auto;
    width: 100vw;
    margin-bottom: 2rem;
    display: block;
}
.txt-3 {    
    padding: 0rem;
}
.product h1{
    font-size: 190%;
    font-weight: 750;
    margin-top: 4%;
}
.txt-3 h2{
    font-size: 150%;
    margin-top: 15%;
    padding-left: 7%;
}
.txt-3 p {
    padding-left: 7%;
    height: auto;
    width: 90vw;
    text-align: justify;    
}
 .game-dev{
    display:grid;
    height: 100%;
    width: 100vw;
    margin-top: 3rem;
    opacity: .9;
}
.game-dev-img{
    display: block;
}
.game-dev-img img{
    height: auto;
    width: 100vw;
}
.game-dev-txt{
    position: relative;
    color: #000;
   text-align: justify;
   font-size: 110%;
   font-weight: 300;
   padding: 1rem;
   z-index: 10;
   margin-top: 0rem;
   margin-bottom: 2rem;
   background-image: none;
}
.game-dev-txt h1 {
    font-size: 150%;
    padding-left: 4%;
    
}
.game-dev-txt p {
    padding: 4%;
    
}
.graphicDesign{
    display: grid;
    margin-top: 0rem;
}
.graphic-img img{
    height:100%;
    width: 100vw;
}
.graphic-txt{
    font-family: Roboto;
    text-align: left;
    height: auto;
    width:100vw;
    font-size: 110%;
    font-weight: 300;
    padding: 7%;
}
.graphic-txt h1 {
    font-size: 150%;
    padding-bottom: 0rem;
    margin-top: 15%;

}
.graphic-txt p{
    text-align: justify;
}
  

.managedIT{
    display: grid;
    margin-top: 0rem;

}
.managedIt-img img{
    display: none;
}
.managedIt-img1{
    height:auto;
    width: 100vw;
    display: block;
}
.managedIt-txt{
    font-family: Roboto;  
    font-size: 110%;
    font-weight: 300;
    padding: 0rem;
    height:auto;
    width: 100vw;
    margin-top: 1rem;
}
.managedIt-txt h1{
    font-size: 150%; 
    padding-left: 7%;
}
.managedIt-txt p{
    margin-top: 1rem;
    padding: 7%;
    text-align: justify;
}
.managedIt-txt ul {
    font-size: 100%;
    list-style: square;
    font-weight: 500;
    padding-left: 20%;
}
.partner{
    display: grid;
    justify-content: center;
    text-align:center;
    height: auto;
    width: 100vw;
    background-color: #F4F4F4;
    padding-top: 3rem;
    margin-top: 3rem;
}
.partner h1{   
    font-size: 200%;
    font-weight: 700;
}
.company {
    display: grid;
    justify-content: center;
}
.company img{    
    position: relative;
    padding: 1rem;
    margin-right: 0rem; 
    
}
.company1, .company3{
    height: auto;
    width:40%;
}
.company2{
    height: auto;
    width: 30%;
}
.company4{
    height: auto;
    width:40%;
}
.client-profile{
    font-family: Roboto;  
    display: grid; 
  justify-content: center;
  height: auto;
  width: 100vw;
    padding-top: 2rem;
}
.client-link{
    padding: 5%
}
.client-link a{
    font-size: 90%;
    font-weight: 500;
    margin-right: 2rem;
    text-align: center;
}
.client-profile h1{
    font-size: 200%;
    text-align: center;
    font-weight: 700;
}
.client-details{
    display: grid; 
    justify-content: center;
    height: auto;
    width: 100vw;
}
.client-details p{
    padding-top: 10px;
    height: auto;
    width: auto;
    font-size: 100%;
    font-weight: 300;
    margin-top: 1rem;
    padding: 0 5% 0 5%;
    text-align: justify;
   
}
.client-info img{
    margin-top: 1rem;
    height: 80px;  

}
.clientImage img{
    height: auto;
    width: 20%;
    margin-right: 2rem;
    margin-top: 2rem;
    }
.client-info{  
    height: auto;
    width: 100vw;
    padding: 1rem;
    margin-right: 0rem;
}
.client-info h4{
    font-size: 120%;
}
.projects{
    font-family: Roboto;
    display: grid;
    justify-content: center;
    background-color: #F4F4F4;
    padding: 0rem;
    height: auto;
    width: 100%;
}
.project-details{
    display: grid;
    justify-content: center;
    margin-left: 0rem;
    height: auto;
    width: 100vw;
    padding: 10%;
}
.project-details label{
    font-size: 150%;
 /* font-size: 30px; */
}
.project-details p{
    font-size: 100%;
    font-weight: 300;
    text-align: justify;
}
.project-txt h1{
    font-size: 200%;
    height: auto;
    width: 100vw;
    writing-mode: unset;
    transform: rotate(0deg);
    text-align:center;
    padding-top:1rem;
}
.footerBody{
    height: auto;
    width: 93%;
    padding: 0;
}
.footer{
    font-family: Roboto;
    display: grid;
    justify-content: center;
    height: auto;
    width: 100%;
    /* padding: 1rem; */
}

.footerBody h1 {
    display: block;
    font-size: 200%;
    font-weight: 700;
    text-align: center;
    padding-top: 5%;
}
.contactDetails {
    display: none;
}
.contactDetails1 {
    display: block;
}
.contactDetails1 label {    
    font-size: 200%;
    font-weight: 700;
    padding-left: 25%;
}
.contactDetails1 p{
    font-size: 100%;
    font-weight: 300;
    padding: 5% 2% 0 5%;
}
.footer-link{
    display: flex;
    justify-content: center;
    padding: 6% 6% 10% 6%;
}
.footer-link  img{
    height: 30px;
    margin-left: 20px;
}
.footer-link a {   
    width: auto !important;   
}
.contactInfo {
    height: auto;
    width: 100%;
}
.form {
    height: auto;
    width: 95%;
}
.form label {
    font-size: 150%;
    font-weight: 700;
}
.form-control{
    border: none !important;
    height: auto! important;
    /* width: 100% ! important; */
}
.upbtn{
    height: auto;
    width: 10%;
    /* opacity: .85; 
    position: fixed;
    float: right;
    z-index: 100;
    right: 2rem;
    bottom: 70px; */
}


}
